import {
  Address,
  ApplicationDocumentUploadStatus,
  BusinessName,
  BusinessStructure,
  BusinessTitle,
  CalculatedTransferAmountType,
  DocumentType as UploadedDocumentType,
  ExternallyInitiatedAchStatus,
  IntegratorInitiatedAchStatus,
  Maybe,
  Name,
  PaymentCardGroupOrderStatus,
  PersonAccountHolderNameFilterInput,
  Phone,
  RecurringAchTransferFrequencyCode,
  ScheduledTransferStatusCode,
  AccountHolderApplicationStatusCode,
  AccountHolderVerificationStatusCode,
  AccountHolderVerificationStatusReasonCode,
  CardDigitalWalletTokenState,
  CardFormFactor,
  CardProductVertical,
  CardUsage,
  CollaborativeAuthorizationEndpointStatus,
  FinancialAccountStatus,
  Iso4217Alpha3CurrencyCode,
  LedgerName,
  NotificationTargetStatus,
  PaymentCardShippingMethod,
  PaymentCardStatus,
  PhoneLabel,
  TransactionEventFilter,
  TransactionEventResponseCode,
  PaymentCardOrderStatus,
  VelocityRuleWindow,
  CardTransactionProcessingType,
  PointOfServiceCategory,
  PanEntryMode,
  PinEntryMode,
  TerminalAttendance,
  VisaFuelType,
  VisaFuelServiceType,
  VisaFuelPurchaseType,
  VisaFuelUnitOfMeasure,
  VisaNonFuelProductCode,
  VisaFleetPurchaseIdentifierFormat,
  AgentServicingVerificationStatus,
  AgentServicingApplicationReviewDecision,
  AgentServicingApplicationDenyReason,
  MerchantCategory,
  WireTransferStatus,
  ExternallyInitiatedAchStatusReasonCode,
  IntegratorInitiatedAchStatusReasonCode,
  WireStatusReasonCode,
  ExternallyInitiatedAchHoldStatus,
  IntegratorInitiatedAchHoldStatus,
  CollaborativeAuthorizationResponseCode,
  IntegratorInitiatedTransferType,
  ExternallyInitiatedTransferType,
  PhoneInput,
  AccountHolderVerificationResultCode,
  DocumentUploadSessionStatusCode,
  AuthorizedUserApplicationStatusCode,
  VisaAmountSignage,
  VisaDiscountTreatment,
  VisaTaxTreatment,
  VisaItemCommodityCode,
  VisaLineItemDetailIndicator,
  AchOriginationType,
  FeeScheduleStatus,
  FeeTransferEventFeeActivityTypeInput,
  FeeTransferEventFeeActivityType,
  AccountClosureBlocker,
  FinancialAccountClosureRequesterType,
  FinancialAccountCloseReason,
} from "@bay1/sdk/generated/graphql";
import type { LogEvent } from "auth0";
import { format } from "date-fns";
import Dinero, { Currency } from "dinero.js";
import title from "title";
import type { DeepReadonly } from "ts-essentials";
import { TransactionEventNode } from "../../hooks/useCardProductTransactionEvents";

import type { USAccountHolderFromQuery } from "../../hooks/useFindAccountHolder";
import { TransferUnion } from "../../hooks/useTransfer";
import type { PartialMoneyFilterInput } from "../filters/transactionEvent/TransactionEventFilterDropdown";

export {
  verificationResultMap,
  VerificationResultStatusForUI,
} from "./formatAccountHolderCardProductApplicationStatuses";
export type { VerificationResultMap } from "./formatAccountHolderCardProductApplicationStatuses";

const numberToMonth = (number: string): string => {
  switch (number) {
    case "01":
      return "Jan";
    case "02":
      return "Feb";
    case "03":
      return "Mar";
    case "04":
      return "Apr";
    case "05":
      return "May";
    case "06":
      return "June";
    case "07":
      return "July";
    case "08":
      return "Aug";
    case "09":
      return "Sept";
    case "10":
      return "Oct";
    case "11":
      return "Nov";
    case "12":
      return "Dec";

    default:
      return "";
  }
};

export function formatTransactionEventName(
  transactionEvent: Maybe<TransactionEventNode>,
): string {
  if (transactionEvent === undefined) {
    return "";
  }

  if (
    transactionEvent.transaction?.__typename === "CreditTransaction" &&
    transactionEvent.__typename === "ClearingEvent"
  ) {
    return "Refund";
  }

  if (
    transactionEvent.transaction?.__typename === "CreditTransaction" &&
    transactionEvent.__typename === "AuthorizationEvent"
  ) {
    return "Refund Authorization";
  }

  if (transactionEvent.__typename === "AuthorizationAndClearEvent") {
    return "Authorization and Clear";
  }

  if (transactionEvent.__typename === "BalanceInquiryEvent") {
    return "Balance Inquiry";
  }

  if (transactionEvent.__typename === "IssuerPreliminaryAuthorizationEvent") {
    return "Pre-Authorization";
  }

  return transactionEvent.__typename.replace("Event", "");
}

export function formatTransactionEventResponseCode(
  responseCode: Maybe<TransactionEventResponseCode>,
): string {
  if (!responseCode) {
    return "";
  }

  if (
    responseCode === TransactionEventResponseCode.APPROVED_FOR_PARTIAL_AMOUNT
  ) {
    return "Partially Approved";
  }
  if (
    responseCode ===
    TransactionEventResponseCode.APPROVED_FOR_PURCHASE_AMOUNT_ONLY
  ) {
    return "Approved - Purchase Only";
  }

  if (responseCode === TransactionEventResponseCode.BAD_CVV) {
    return "Bad CVV";
  }

  return title(responseCode.replaceAll("_", " "));
}

export const formatCardUsage = (usage: Maybe<CardUsage>): string => {
  switch (usage) {
    case CardUsage.MULTI_USE:
      return "Multi-use";
    case CardUsage.SINGLE_USE:
      return "Single-use";

    default:
      return "";
  }
};

export const formatCardVertical = (
  vertical: Maybe<CardProductVertical>,
): string => {
  switch (vertical) {
    case CardProductVertical.AP_INVOICE_AUTOMATION:
      return "AP Automation";
    case CardProductVertical.EARNED_WAGE_ACCESS:
      return "Earned Wage Access";
    case CardProductVertical.GENERAL_PURPOSE_RELOADABLE:
      return "Consumer Debit";
    case CardProductVertical.COMMERCIAL_DEBIT:
      return "Commercial Debit";
    case CardProductVertical.SECURED_COMMERCIAL_CREDIT:
      return "Secured Commercial Charge";
    case CardProductVertical.FLEET:
      return "Fleet";
    case CardProductVertical.COMMERCIAL_PREPAID:
      return "Commercial Prepaid";
    case CardProductVertical.CONSUMER_PREPAID:
      return "Consumer Prepaid";
    case CardProductVertical.COMMERCIAL_CREDIT_PAY_IN_FULL:
      return "Commercial Charge";
    case CardProductVertical.PAYROLL:
      return "Payroll";

    default:
      return "";
  }
};

export const formatCardVerticalForCreateFlow = (
  vertical: Maybe<CardProductVertical>,
): string => {
  switch (vertical) {
    case CardProductVertical.AP_INVOICE_AUTOMATION:
      return "AP Automation";
    case CardProductVertical.EARNED_WAGE_ACCESS:
      return "Earned Wage Access";
    case CardProductVertical.GENERAL_PURPOSE_RELOADABLE:
      return "Debit";
    case CardProductVertical.COMMERCIAL_DEBIT:
      return "Debit";
    case CardProductVertical.SECURED_COMMERCIAL_CREDIT:
      return "Secured Charge";
    case CardProductVertical.FLEET:
      return "Fleet";
    case CardProductVertical.COMMERCIAL_PREPAID:
      return "Prepaid";
    case CardProductVertical.CONSUMER_PREPAID:
      return "Prepaid";
    case CardProductVertical.COMMERCIAL_CREDIT_PAY_IN_FULL:
      return "Charge";
    case CardProductVertical.PAYROLL:
      return "Payroll";

    default:
      return "";
  }
};

export const formatSpendRuleTypes = (string: Maybe<string>): string => {
  switch (string) {
    case "MerchantCategorySpendRule":
      return "Category Code";
    case "MerchantCountrySpendRule":
      return "Country Code";
    case "AmountLimitSpendRule":
      return "Amount";
    case "CVVSpendRule":
      return "CVV";
    case "StreetAddressSpendRule":
      return "Street Address";
    case "MerchantIdentifierSpendRule":
      return "Merchant Identifier";
    case "PointOfServiceCategorySpendRule":
      return "Point of Service";
    case "CountLimitSpendRule":
      return "Count Limit";

    default:
      return "";
  }
};

export const formatSpendRuleResultTypes = (string: Maybe<string>): string => {
  switch (string) {
    case "MerchantCategorySpendRuleResult":
      return "Category Code";
    case "MerchantCountrySpendRuleResult":
      return "Country Code";
    case "AmountLimitSpendRuleResult":
      return "Amount";
    case "CVVSpendRuleResult":
      return "CVV";
    case "StreetAddressSpendRuleResult":
      return "Street Address";
    case "MerchantIdentifierSpendRuleResult":
      return "Merchant Identifier";

    default:
      return "";
  }
};

export const composeAccountHolderName = (
  name: Maybe<Name> | undefined,
): string => {
  if (!name) {
    return "";
  }

  const { givenName, middleName, familyName, title: nameTitle, suffix } = name;

  return `${nameTitle ?? ""} ${givenName} ${middleName ?? ""} ${familyName} ${
    suffix ?? ""
  }`;
};

export const composeBusinessAccountHolderName = (
  name: Maybe<BusinessName>,
): string => {
  if (!name) {
    return "";
  }

  const { doingBusinessAsName, legalBusinessName } = name;

  if (doingBusinessAsName === "") {
    return legalBusinessName;
  }

  return doingBusinessAsName ?? legalBusinessName;
};

const businessStructureTransforms: Record<
  "LLC",
  (businessStructure: BusinessStructure) => string
> = {
  LLC: () => "LLC",
};

type businessStructureKey = keyof typeof businessStructureTransforms;

export const presentBusinessStructure = (
  businessStructure?: BusinessStructure,
): string | undefined => {
  if (!businessStructure) {
    return undefined;
  }

  const shouldTransform = Object.keys(businessStructureTransforms).includes(
    businessStructure,
  );

  if (shouldTransform) {
    return businessStructureTransforms[
      businessStructure as businessStructureKey
    ](businessStructure);
  }
  return title(businessStructure.replaceAll("_", " "));
};

const jobTitleTransforms: Record<
  "CEO" | "CFO" | "COO",
  (jobTitle: BusinessTitle) => string
> = {
  CEO: () => "CEO",
  CFO: () => "CFO",
  COO: () => "COO",
};

type jobTitleKey = keyof typeof jobTitleTransforms;

export const presentJobTitle = (businessTitle?: BusinessTitle): string => {
  if (!businessTitle) {
    return "";
  }

  const shouldTransform =
    Object.keys(jobTitleTransforms).includes(businessTitle);

  if (shouldTransform) {
    return jobTitleTransforms[businessTitle as jobTitleKey](businessTitle);
  }
  return title(businessTitle.replaceAll("_", " "));
};

export const presentDateOfBirth = (dateOfBirth?: string) =>
  dateOfBirth === undefined
    ? "N/A"
    : format(new Date(dateOfBirth.replaceAll("-", "/")), "MMM do, yyyy");

export const formatPhoneNumberLabel = (
  phoneNumbers: Readonly<Maybe<Phone[]>>,
) => ((phoneNumbers || []).length > 1 ? "Phone Numbers" : "Phone Number");

export const formatPercentage = (value?: number) => {
  const percentSymbol = (value ?? "") === "" ? "" : "%";
  return `${value ?? ""}${percentSymbol}`;
};

export const presentAccountHolderType = (
  accountHolder: USAccountHolderFromQuery,
): string => {
  if (accountHolder?.__typename === "USPersonAccountHolder") {
    return "Person";
  }

  if (accountHolder?.__typename === "USBusinessAccountHolder") {
    return "Business";
  }

  return "";
};

export const composeLinePersonalization = (
  name: Maybe<Name> | undefined,
): string => {
  if (!name) {
    return "";
  }

  const { givenName, familyName, suffix } = name;

  if (suffix !== undefined && suffix !== "") {
    return `${givenName} ${familyName} ${suffix}`;
  }

  return `${givenName} ${familyName}`;
};

export const composeAddress = (address: Maybe<Address>): string => {
  if (!address) {
    return "";
  }

  const { streetAddress, extendedAddress, postalCode, region, locality } =
    address;

  if (extendedAddress !== "") {
    return `${streetAddress} ${extendedAddress}, ${locality}, ${region} ${postalCode}`;
  }

  return `${streetAddress}, ${locality}, ${region} ${postalCode}`;
};

export const composeLocation = (address?: Address) => {
  if (!address) {
    return undefined;
  }

  const locality = address.locality ?? "";
  const separator = locality === "" ? "" : ", ";

  return `${title(locality)}${separator}${address.region ?? ""}`;
};

export const formatMerchantDetail = (
  detail: Maybe<string>,
  defaultValue = "N/A",
): string => detail || defaultValue;

export const formatAccountHolderApplicationStatusCode = (
  status?: AccountHolderApplicationStatusCode,
): string => {
  switch (status) {
    case AccountHolderApplicationStatusCode.APPROVED:
      return "Approved";
    case AccountHolderApplicationStatusCode.IN_REVIEW:
      return "In Review";
    case AccountHolderApplicationStatusCode.PENDING:
      return "Pending";
    case AccountHolderApplicationStatusCode.DENIED:
      return "Denied";
    case AccountHolderApplicationStatusCode.CLOSED:
      return "Closed";

    default:
      return "";
  }
};

export const formatAccountHolderVerificationStatusReasonCode = (
  status?: AccountHolderVerificationStatusReasonCode,
): string => {
  switch (status) {
    case AccountHolderVerificationStatusReasonCode.DOCUMENT_UPLOAD_REQUIRED:
      return "Document Upload Required";
    case AccountHolderVerificationStatusReasonCode.IN_REVIEW:
      return "In Review";
    case AccountHolderVerificationStatusReasonCode.PENDING:
      return "Pending";
    case AccountHolderVerificationStatusReasonCode.DENIED:
      return "Denied";
    case AccountHolderVerificationStatusReasonCode.KBA_REQUIRED:
      return "KBA Required";
    case AccountHolderVerificationStatusReasonCode.PASSED:
      return "Passed";
    case AccountHolderVerificationStatusReasonCode.REVIEW_REQUIRED:
      return "Review Required";

    default:
      return "";
  }
};

export const formatAccountHolderVerificationStatusCode = (
  status?: AccountHolderVerificationStatusCode,
): string => {
  switch (status) {
    case AccountHolderVerificationStatusCode.PASSED:
      return "Passed";
    case AccountHolderVerificationStatusCode.PENDING:
      return "Pending";
    case AccountHolderVerificationStatusCode.DENIED:
      return "Denied";

    default:
      return "";
  }
};

export const formatAccountHolderVerificationReason = (
  reason?: AccountHolderVerificationStatusReasonCode,
): string => {
  switch (reason) {
    case AccountHolderVerificationStatusReasonCode.DENIED:
      return "Denied";
    case AccountHolderVerificationStatusReasonCode.DOCUMENT_UPLOAD_REQUIRED:
      return "Document Upload(s) Required";
    case AccountHolderVerificationStatusReasonCode.IN_REVIEW:
      return "In Review";
    case AccountHolderVerificationStatusReasonCode.KBA_REQUIRED:
      return "KBA Required";
    case AccountHolderVerificationStatusReasonCode.PASSED:
      return "Passed";
    case AccountHolderVerificationStatusReasonCode.PENDING:
      return "Pending";
    case AccountHolderVerificationStatusReasonCode.REVIEW_REQUIRED:
      return "Review Required";

    default:
      return "";
  }
};

export const formatPhoneLabel = (label?: PhoneLabel): string => {
  switch (label) {
    case PhoneLabel.HOME:
      return "Home";
    case PhoneLabel.MOBILE:
      return "Mobile";
    case PhoneLabel.WORK:
      return "Work";

    default:
      return "";
  }
};

export const formatPaymentCardStatus = (status?: PaymentCardStatus): string => {
  switch (status) {
    case PaymentCardStatus.ACTIVATION_REQUIRED:
      return "Activation Required";
    case PaymentCardStatus.ACTIVE:
      return "Active";
    case PaymentCardStatus.SUSPENDED:
      return "Suspended";
    case PaymentCardStatus.CLOSED:
      return "Closed";

    default:
      return "";
  }
};

export const formatNotificationTargetStatus = (
  status?: NotificationTargetStatus,
): string => {
  switch (status) {
    case NotificationTargetStatus.ACTIVE:
      return "Active";
    case NotificationTargetStatus.DEACTIVATED:
      return "Deactivated";
    case NotificationTargetStatus.PENDING_VERIFICATION:
      return "Pending";
    case NotificationTargetStatus.ACTIVATION_FAILED:
      return "Activation Failed";

    default:
      return "";
  }
};

export const formatCollabAuthEndpointStatus = (
  status?: CollaborativeAuthorizationEndpointStatus,
): string => {
  switch (status) {
    case CollaborativeAuthorizationEndpointStatus.ACTIVE:
      return "Active";
    case CollaborativeAuthorizationEndpointStatus.DEACTIVATED:
      return "Deactivated";
    case CollaborativeAuthorizationEndpointStatus.PENDING_VERIFICATION:
      return "Pending";
    case CollaborativeAuthorizationEndpointStatus.ACTIVATION_FAILED:
      return "Activation Failed";

    default:
      return "";
  }
};

export const formatTransactionEventFilter = (
  status?: TransactionEventFilter,
): string => {
  switch (status) {
    case TransactionEventFilter.AUTHORIZATION_EVENT:
      return "Authorization";
    case TransactionEventFilter.REVERSAL_EVENT:
      return "Reversal";
    case TransactionEventFilter.CLEARING_EVENT:
      return "Clearing";

    default:
      return "";
  }
};

export const formatShippingMethod = (
  method: Maybe<PaymentCardShippingMethod>,
): string => {
  switch (method) {
    case PaymentCardShippingMethod.USPS_GROUND:
      return "USPS Ground";
    case PaymentCardShippingMethod.USPS_PRIORITY:
      return "USPS Priority";
    case PaymentCardShippingMethod.USPS_EXPRESS:
      return "USPS Express";
    case PaymentCardShippingMethod.UPS_GROUND:
      return "UPS Ground";
    case PaymentCardShippingMethod.UPS_SECOND_DAY:
      return "UPS Second Day";
    case PaymentCardShippingMethod.UPS_NEXT_DAY:
      return "UPS Next Day";

    default:
      return "";
  }
};

export const formatFormFactor = (factor?: CardFormFactor): string => {
  switch (factor) {
    case CardFormFactor.VIRTUAL:
      return "Virtual";
    case CardFormFactor.PHYSICAL:
      return "Physical";

    default:
      return "";
  }
};

export const formatLedgerName = (name: Maybe<LedgerName>): string => {
  if (!name) {
    return "";
  }

  if (name === LedgerName.OUTSTANDING_BALANCE_PAYABLE) {
    return "Outstanding Balance";
  }

  if (name === LedgerName.ACH_PENDING_SETTLEMENT) {
    return "ACH Pending Settlement";
  }

  if (name === LedgerName.ACH_PENDING_SETTLEMENT_OUT) {
    return "ACH Pending Settlement Out";
  }

  return title(name.replaceAll("_", " "));
};

export const formatAmount = (
  number: number,
  fourImpliedDecimalPlaces?: boolean,
): string => {
  if (fourImpliedDecimalPlaces) {
    return (number / 10000).toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }
  return (number / 100).toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export const formatCurrencyCodeSymbol = (
  currencyCode: Maybe<string>,
): string => {
  switch (currencyCode) {
    case Iso4217Alpha3CurrencyCode.USD:
    case Iso4217Alpha3CurrencyCode.AUD:
    case Iso4217Alpha3CurrencyCode.CAD:
    case Iso4217Alpha3CurrencyCode.HKD:
    case Iso4217Alpha3CurrencyCode.MXN:
    case Iso4217Alpha3CurrencyCode.SGD:
      return "$";
    case Iso4217Alpha3CurrencyCode.EUR:
      return "€";
    case Iso4217Alpha3CurrencyCode.GBP:
      return "£";
    case Iso4217Alpha3CurrencyCode.JPY:
    case Iso4217Alpha3CurrencyCode.CNY:
      return "¥";

    default:
      return "$";
  }
};

export const getCurrencyDecimals = (currencyCode: string) => {
  const hasZeroDecimals =
    currencyCode === "CVE" ||
    currencyCode === "DJF" ||
    currencyCode === "GNF" ||
    currencyCode === "IDR" ||
    currencyCode === "JPY" ||
    currencyCode === "KMF" ||
    currencyCode === "KRW" ||
    currencyCode === "PYG" ||
    currencyCode === "RWF" ||
    currencyCode === "UGX" ||
    currencyCode === "VND" ||
    currencyCode === "VUV" ||
    currencyCode === "XAF" ||
    currencyCode === "XOF" ||
    currencyCode === "XPF";

  const hasThreeDecimals =
    currencyCode === "BHD" ||
    currencyCode === "IQD" ||
    currencyCode === "JOD" ||
    currencyCode === "KWD" ||
    currencyCode === "LYD" ||
    currencyCode === "OMR" ||
    currencyCode === "TND";

  if (hasZeroDecimals) {
    return 0;
  } else if (hasThreeDecimals) {
    return 3;
  } else {
    return 2;
  }
};

export const formatCurrencyAmount = (
  amount: number,
  currencyCode: Maybe<string>,
  truncateDecimals: boolean = false,
  fourImpliedDecimals: boolean = false,
) => {
  const currencyDecimals = getCurrencyDecimals(currencyCode ?? "USD");

  const currencyAmount = Dinero({
    amount: amount,
    currency: (currencyCode ?? "USD") as Currency,
    precision: fourImpliedDecimals ? currencyDecimals + 2 : currencyDecimals,
  });

  if (truncateDecimals) {
    return currencyAmount.toFormat("$0,0");
  }

  switch (currencyDecimals) {
    case 0: {
      return currencyAmount.toFormat("$0,0");
    }
    case 3: {
      return currencyAmount.toFormat("$0,0.000");
    }
    default: {
      return currencyAmount.toFormat("$0,0.00");
    }
  }
};

export const formatQuantityAmount = (
  number: number,
  impliedFourDecimals: boolean = false,
) => {
  const denominator = impliedFourDecimals ? 10000 : 100;

  return (number / denominator).toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export const formatPercentAmount = (
  number: number,
  impliedFourDecimals: boolean = false,
) => {
  return `${formatQuantityAmount(number, impliedFourDecimals)}%`;
};

export const formatAmountSymbol = (
  string: Maybe<keyof PartialMoneyFilterInput>,
): string => {
  switch (string) {
    case "equals":
      return "= ";
    case "greaterThan":
      return "> ";
    case "greaterThanOrEquals":
      return ">= ";
    case "lessThan":
      return "< ";
    case "lessThanOrEquals":
      return "<= ";

    default:
      return "";
  }
};

// Initial code taken from commit 4bf87c0, updated via our linting rules.
export const formatSSN = (ssn: string): string => {
  const formattedSSN = ssn
    .replace(/\D/gu, "")
    .replace(/^(\d{3})/u, "$1-")
    .replace(/-(\d{2})/u, "-$1-")
    .replace(/(\d)-(\d{4}).*/u, "$1-$2");
  return formattedSSN.slice(0, 11);
};

export const formatPhoneNumber = (phone: Maybe<string>): string => {
  if (phone === undefined) {
    return "";
  }
  const formattedPhoneNumber = phone
    .replace(/[^\d-]/gu, "")
    .replace(/^(\d{3})-?(\d{1,3})/u, "$1-$2")
    .replace(/^(\d{3})-?(\d{3})-?(\d{1,4})/u, "$1-$2-$3");
  return formattedPhoneNumber.slice(0, 12);
};

export const formatPostalCode = (code: string): string => {
  const formattedPostalCode = code
    .replace(/[^\d-]/gu, "")
    .replace(/^(\d{5})-?(\d{1,4})/u, "$1-$2");
  return formattedPostalCode.slice(0, 10);
};

export const formatCustomDate = (date: Maybe<string>): string => {
  if (date === undefined) {
    return "";
  }
  const formattedDate = date
    .replace(/[^\d-]/gu, "")
    .replace(/^(\d{2})-?(\d{1,2})/u, "$1-$2")
    .replace(/^(\d{2})-?(\d{2})-?(\d{1,4})/u, "$1-$2-$3")
    .replace("-", "/")
    .replace("-", "/");
  return formattedDate.slice(0, 8);
};

export const composeAccountHolderNameFromFilter = (
  name: DeepReadonly<PersonAccountHolderNameFilterInput> | undefined,
): string => {
  if (!name) {
    return "";
  }
  const { givenName, middleName, familyName, title: nameTitle, suffix } = name;

  return `${nameTitle?.soundsLike ?? ""} ${givenName?.soundsLike ?? ""} ${
    middleName?.soundsLike ?? ""
  } ${familyName?.soundsLike ?? ""} ${suffix?.soundsLike ?? ""}`;
};

export const formatEmployerIdentificationNumber = (value: string): string => {
  const employerIdentificationNumber = value.replace(/\D/gu, "");

  const employerIdentificationNumberLength =
    employerIdentificationNumber.length;

  if (employerIdentificationNumberLength < 3) {
    return employerIdentificationNumber;
  }

  return `${employerIdentificationNumber.slice(
    0,
    2,
  )}-${employerIdentificationNumber.slice(2)}`;
};

export const formatAccountHolderNameFilterInput = (
  name: string,
): Maybe<PersonAccountHolderNameFilterInput> => {
  const splitBySpaces = name.split(" ");

  if (splitBySpaces.length === 5) {
    return {
      title: { soundsLike: [splitBySpaces[0]] },
      givenName: { soundsLike: [splitBySpaces[1]] },
      middleName: { soundsLike: [splitBySpaces[2]] },
      familyName: { soundsLike: [splitBySpaces[3]] },
      suffix: { soundsLike: [splitBySpaces[4]] },
    };
  }

  if (splitBySpaces.length === 2) {
    return {
      givenName: { soundsLike: [splitBySpaces[0]] },
      familyName: { soundsLike: [splitBySpaces[1]] },
    };
  }

  if (splitBySpaces.length === 1) {
    return {
      givenName: { soundsLike: [splitBySpaces[0]] },
    };
  }

  if (splitBySpaces.length === 3) {
    return {
      givenName: { soundsLike: [splitBySpaces[0]] },
      middleName: { soundsLike: [splitBySpaces[1]] },
      familyName: { soundsLike: [splitBySpaces[2]] },
    };
  }

  return undefined;
};

export const formatExpirationDate = (
  expirationMonth: Maybe<string>,
  expirationYear: Maybe<string>,
) => {
  if (expirationMonth === undefined || expirationYear === undefined) {
    return "";
  }

  return `${expirationMonth}/${expirationYear}`;
};

export const formatTransferDate = (scheduledTransferDate: string): string => {
  const [_year, month, day] = scheduledTransferDate.split("T")[0].split("-");

  return day.startsWith("0")
    ? `${numberToMonth(month)} ${day[1]}`
    : `${numberToMonth(month)} ${day}`;
};

export const formatScheduledTransferStatus = (
  status: Maybe<ScheduledTransferStatusCode>,
): string => (status ? title(status) : "");

export const formatCalculatedType = (
  calculatedType: Maybe<CalculatedTransferAmountType>,
): string => {
  switch (calculatedType) {
    case CalculatedTransferAmountType.OUTSTANDING_BALANCE:
      return "Balance";
    case CalculatedTransferAmountType.OUTSTANDING_STATEMENT_BALANCE:
      return "Statement Balance";

    default:
      return "";
  }
};

export const formatRecurringACHTransferFrequency = (
  frequency: Maybe<RecurringAchTransferFrequencyCode>,
): string => (frequency ? title(frequency) : "");

export const formatPointsLedger = (number: number): string =>
  number.toLocaleString("en-US");

export const formatIntegratorInitiatedAchStatusCode = (
  status: Maybe<IntegratorInitiatedAchStatus>,
): string => (status ? title(status) : "");

export const formatExternallyInitiatedAchStatusCode = (
  status: Maybe<ExternallyInitiatedAchStatus>,
): string => (status ? title(status) : "");

export const formatWireTransferStatus = (
  status: Maybe<WireTransferStatus>,
): string => (status ? title(status) : "");

export const formatExternallyInitiatedAchHoldStatus = (
  status: Maybe<ExternallyInitiatedAchHoldStatus>,
): string => (status ? title(status.replaceAll("_", " ")) : "");

export const formatIntegratorInitiatedAchHoldStatus = (
  status: Maybe<IntegratorInitiatedAchHoldStatus>,
): string => (status ? title(status.replaceAll("_", " ")) : "");

export const formatPhoneNumberSearch = (phone: string): string => {
  const formattedPhoneNumber = phone
    .replace(/[^\d-]/gu, "")
    .replace(/^(\d{3})-?(\d{1,3})/u, "$1-$2")
    .replace(/^(\d{3})-?(\d{3})-?(\d{1,4})/u, "$1-$2-$3");
  return formattedPhoneNumber.slice(0, 12);
};

export const formatLast4Search = (last4: string): string =>
  last4.slice(6).replace(/\D/gu, "").slice(0, 4);

export const formatDocumentType = (type: Maybe<UploadedDocumentType>): string =>
  type ? title(type.replaceAll("_", " ")) : "";

export const formatUploadedDocumentStatus = (
  status: Maybe<ApplicationDocumentUploadStatus>,
): string => (status ? title(status.replaceAll("_", " ")) : "");

export const formatFinancialAccountStatus = (
  status: Maybe<FinancialAccountStatus>,
): string => {
  switch (status) {
    case FinancialAccountStatus.ACTIVE:
      return "Active";
    case FinancialAccountStatus.CLOSED:
      return "Closed";
    case FinancialAccountStatus.PENDING_CLOSURE:
      return "Pending Closure";
    case FinancialAccountStatus.SUSPENDED:
      return "Suspended";
    case FinancialAccountStatus.UNDER_REVIEW:
      return "Under Review";

    default:
      return "";
  }
};

export const formatLocationInfo = (
  locationInfo: LogEvent["location_info"],
): string => {
  if (!locationInfo) {
    return "";
  }

  return `${locationInfo.city_name}, ${locationInfo.country_code}`;
};

export const formatDigitalWalletCardTokenStatus = (
  status: Maybe<CardDigitalWalletTokenState>,
): string => {
  switch (status) {
    case CardDigitalWalletTokenState.ACTIVE:
      return "Active";
    case CardDigitalWalletTokenState.REQUESTED:
      return "Requested";
    case CardDigitalWalletTokenState.SUSPENDED:
      return "Suspended";
    case CardDigitalWalletTokenState.TERMINATED:
      return "Terminated";
    default:
      return "";
  }
};

export const formatGroupOrderStatusCode = (
  status?: PaymentCardGroupOrderStatus,
): string => {
  switch (status) {
    case PaymentCardGroupOrderStatus.NEW:
      return "New";
    case PaymentCardGroupOrderStatus.PENDING:
      return "Pending";
    case PaymentCardGroupOrderStatus.SENT_TO_PRINTER:
      return "Sent To Printer";
    case PaymentCardGroupOrderStatus.APPROVED:
      return "Approved";
    case PaymentCardGroupOrderStatus.CANCELED:
      return "Canceled";
    case PaymentCardGroupOrderStatus.SHIPPED:
      return "Shipped";
    case PaymentCardGroupOrderStatus.SHIP_FAILED:
      return "Ship Failed";

    default:
      return "";
  }
};

export const formatRequestedShipDate = (shipDate: Maybe<string>): string => {
  if (!shipDate || shipDate === "") {
    return "";
  }

  const [year, month, day] = shipDate.split("T")[0].split("-");

  return day.startsWith("0")
    ? `${numberToMonth(month)} ${day[1]}, ${year}`
    : `${numberToMonth(month)} ${day}, ${year}`;
};

export const formatPaymentCardOrderStatus = (
  status: Maybe<PaymentCardOrderStatus>,
): string => {
  switch (status) {
    case PaymentCardOrderStatus.NEW:
      return "New";
    case PaymentCardOrderStatus.SENT_TO_PRINTER:
      return "Sent To Printer";
    case PaymentCardOrderStatus.APPROVED:
      return "Approved";
    case PaymentCardOrderStatus.CANCELED:
      return "Canceled";
    case PaymentCardOrderStatus.SHIPPED:
      return "Shipped";
    case PaymentCardOrderStatus.SHIP_FAILED:
      return "Ship Failed";

    default:
      return "";
  }
};

export const formatVelocityRuleWindow = (
  window: Maybe<VelocityRuleWindow>,
): string => {
  switch (window) {
    case VelocityRuleWindow.DAILY:
      return "Daily";
    case VelocityRuleWindow.WEEKLY:
      return "Weekly";
    case VelocityRuleWindow.MONTHLY:
      return "Monthly";
    case VelocityRuleWindow.NINETY_DAYS:
      return "Ninety Days";
    case VelocityRuleWindow.YEARLY:
      return "Yearly";
    case VelocityRuleWindow.PER_TRANSACTION:
      return "Per Transaction";
    default:
      return "";
  }
};

export const formatVelocityRuleAdverbToNoun = (
  window: Maybe<VelocityRuleWindow>,
): string => {
  switch (window) {
    case VelocityRuleWindow.DAILY:
      return "day";
    case VelocityRuleWindow.WEEKLY:
      return "week";
    case VelocityRuleWindow.MONTHLY:
      return "month";
    case VelocityRuleWindow.NINETY_DAYS:
      return "90 days";
    case VelocityRuleWindow.YEARLY:
      return "year";
    case VelocityRuleWindow.PER_TRANSACTION:
      return "transaction";
    default:
      return "";
  }
};

export const formatTruncateRuleName = (name: Maybe<string | null>): string => {
  if (name && name.length > 30) {
    return name.substring(0, 30) + "...";
  }
  if (name) {
    return name;
  }
  return "";
};
export const formatCardTransactionProcessingType = (
  processingType: Maybe<CardTransactionProcessingType>,
): string => (processingType ? title(processingType.replaceAll("_", " ")) : "");

export const formatPointOfServiceDetailsCategory = (
  category: Maybe<PointOfServiceCategory>,
): string => (category ? title(category.replaceAll("_", " ")) : "Not Provided");

export const formatPanEntryMode = (entrymode: Maybe<PanEntryMode>): string =>
  entrymode ? title(entrymode.replaceAll("_", " ")) : "Not Provided";

export const formatPinEntryMode = (entrymode: Maybe<PinEntryMode>): string =>
  entrymode ? title(entrymode.replaceAll("_", " ")) : "Not Provided";

export const formatTerminalAttendance = (
  terminalAttendance: Maybe<TerminalAttendance>,
): string =>
  terminalAttendance
    ? title(terminalAttendance.replaceAll("_", " "))
    : "Not Provided";

export const formatFuelType = (fuelType: Maybe<VisaFuelType>): string =>
  fuelType ? title(fuelType.replaceAll("_", " ")) : "Not Provided";

export const formatFuelPurchaseType = (
  fuelPurchaseType: Maybe<VisaFuelPurchaseType>,
): string =>
  fuelPurchaseType
    ? title(fuelPurchaseType.replaceAll("_", " "))
    : "Not Provided";

export const formatFuelServiceType = (
  fuelServiceType: Maybe<VisaFuelServiceType>,
): string =>
  fuelServiceType
    ? title(fuelServiceType.replaceAll("_", " "))
    : "Not Provided";

export const formatFuelUnitOfMeasure = (
  unitOfMeasure: Maybe<VisaFuelUnitOfMeasure>,
  isPerUnit?: boolean,
): string => {
  if (!unitOfMeasure) {
    return "";
  }

  if (isPerUnit ?? false) {
    return unitOfMeasure === VisaFuelUnitOfMeasure.CHARGING_MINUTES
      ? "Charging Minute"
      : title(unitOfMeasure.replaceAll("_", " "));
  }

  return unitOfMeasure === VisaFuelUnitOfMeasure.CHARGING_MINUTES
    ? "Charging Minutes"
    : `${title(unitOfMeasure.replaceAll("_", " "))}`.concat("s");
};

export const formatNonFuelProductCode = (
  productCode: Maybe<VisaNonFuelProductCode>,
): string =>
  productCode ? title(productCode.replaceAll("_", " ")) : "Not Provided";

export const formatPurchaseIdentifierFormat = (
  purchaseIdentifierFormat: Maybe<VisaFleetPurchaseIdentifierFormat>,
): string =>
  purchaseIdentifierFormat
    ? title(purchaseIdentifierFormat.replaceAll("_", " "))
    : "";

export const formatAgentServicingVerificationStatus = (
  status?: AgentServicingVerificationStatus,
): string => {
  switch (status) {
    case AgentServicingVerificationStatus.PASSED:
      return "Passed";
    case AgentServicingVerificationStatus.DENIED:
      return "Denied";
    default:
      return "";
  }
};

export const formatAgentServicingApplicationReviewDecision = (
  decision?: AgentServicingApplicationReviewDecision,
): string => {
  switch (decision) {
    case AgentServicingApplicationReviewDecision.APPROVED:
      return "Approved";
    case AgentServicingApplicationReviewDecision.DENIED:
      return "Denied";
    default:
      return "";
  }
};

export const formatAgentServicingApplicationDenyReason = (
  reason?: AgentServicingApplicationDenyReason,
): string => {
  if (!reason) {
    return "";
  }
  const withoutPrefix = reason.split("DENIED_DUE_TO_")[1];
  return title(withoutPrefix.replaceAll("_", " "));
};

export const formatMerchantDetailCategory = (
  category: Maybe<MerchantCategory>,
): string => (category ? title(category.replaceAll("_", " ")) : "Not Provided");

export const formatTransferType = (transfer: TransferUnion): string => {
  if (transfer?.__typename === "PayrollTransfer") {
    return "Payroll (Non-Originated ACH)";
  }
  if (transfer?.__typename === "CreditFunds") {
    return "Credit (Non-Originated ACH)";
  }

  if (transfer?.__typename === "DebitFunds") {
    return "Debit (Non-Originated ACH)";
  }
  if (transfer?.__typename === "SecureDeposit") {
    return "Secured Deposit (Non-Originated ACH)";
  }
  if (transfer?.__typename === "SecureDepositACHTransfer") {
    return "Secured Deposit (Originated ACH)";
  }
  if (
    transfer?.__typename === "IntegratorInitiatedFundsWithdrawalACHTransfer"
  ) {
    return "Withdrawal (Originated ACH)";
  }

  if (transfer?.__typename === "IntegratorInitiatedFundsDepositACHTransfer") {
    return "Deposit (Originated ACH)";
  }
  if (transfer?.__typename === "SecureCardBalanceRepaymentACHTransfer") {
    return "Repayment (Originated ACH)";
  }
  if (transfer?.__typename === "ExternallyInitiatedWireTransfer") {
    return "Wire Transfer (Non-Originated ACH)";
  }
  return "";
};

export const formatAchOriginationType = (status: Maybe<string>): string => {
  switch (status) {
    case AchOriginationType.NON_ORIGINATED:
      return "non-originated";
    case AchOriginationType.ORIGINATED:
      return "originated";
    default:
      return "";
  }
};

export const formatExternallyInitiatedAchStatusReasonCode = (
  statusReasonCode: ExternallyInitiatedAchStatusReasonCode,
): string =>
  statusReasonCode ? title(statusReasonCode.replaceAll("_", " ")) : "";

export const formatIntegratorInitiatedAchStatusReasonCode = (
  statusReasonCode: IntegratorInitiatedAchStatusReasonCode,
): string =>
  statusReasonCode ? title(statusReasonCode.replaceAll("_", " ")) : "";

export const formatWireStatusReasonCode = (
  statusReasonCode: WireStatusReasonCode,
): string =>
  statusReasonCode ? title(statusReasonCode.replaceAll("_", " ")) : "";

export const formatCollaborativeAuthorizationResponseCode = (
  collaborativeAuthorizationResponseCode: Maybe<CollaborativeAuthorizationResponseCode>,
): string => {
  if (
    collaborativeAuthorizationResponseCode ===
    CollaborativeAuthorizationResponseCode.PARTIAL_AMOUNT_APPROVED
  ) {
    return "Partially Approved";
  }
  return (
    title(collaborativeAuthorizationResponseCode?.replaceAll("_", " ")) ?? ""
  );
};

export const formateTruncateAccountHolderName = (
  accountHolderName: Maybe<string | null>,
): string => {
  if (accountHolderName && accountHolderName.length > 30) {
    return accountHolderName.substring(0, 30) + "...";
  }
  if (accountHolderName) {
    return accountHolderName;
  }
  return "";
};

export const formatIntegratorInitiatedTransferType = (
  transferType?: IntegratorInitiatedTransferType,
): string => {
  switch (transferType) {
    case IntegratorInitiatedTransferType.SECURE_DEPOSIT:
      return "Secure Deposit";
    case IntegratorInitiatedTransferType.REPAYMENT:
      return "Repayment";
    case IntegratorInitiatedTransferType.FUNDS_WITHDRAWAL:
      return "Withdrawal";
    case IntegratorInitiatedTransferType.FUNDS_DEPOSIT:
      return "Deposit";

    default:
      return "";
  }
};

export const formatExternallyInitiatedTransferType = (
  transferType?: ExternallyInitiatedTransferType,
): string => {
  switch (transferType) {
    case ExternallyInitiatedTransferType.CREDIT_FUNDS:
      return "Credit";
    case ExternallyInitiatedTransferType.DEBIT_FUNDS:
      return "Debit";
    case ExternallyInitiatedTransferType.PAYROLL:
      return "Payroll";

    default:
      return "";
  }
};

export const formatTransferRuleTypes = (string: Maybe<string>): string => {
  switch (string) {
    case "ACHAmountLimitTransferRule":
      return "Amount";
    case "ACHCountLimitTransferRule":
      return "Count";
    case "ACHStatusTransferRule":
      return "Status";
    case "ACHReturnCodeTransferRule":
      return "Return Code";

    default:
      return "";
  }
};

export const formatPhoneInput = (input: PhoneInput): PhoneInput => ({
  countryCode: input.countryCode,
  label: input.label,
  number: input.number.replaceAll("-", ""),
});

export const formatSOSTags = (
  tags: Maybe<AccountHolderVerificationResultCode>[],
): { result: string; icon: string } => {
  const matchString = ((
    tags: Maybe<AccountHolderVerificationResultCode>[],
  ): string => {
    if (
      tags.includes(AccountHolderVerificationResultCode.SOS_MISMATCH) &&
      !tags.includes(AccountHolderVerificationResultCode.SOS_MATCH)
    ) {
      return "No states matched.";
    }

    if (
      !tags.includes(AccountHolderVerificationResultCode.SOS_MISMATCH) &&
      tags.includes(AccountHolderVerificationResultCode.SOS_MATCH)
    ) {
      return "All states matched.";
    }

    if (
      tags.includes(AccountHolderVerificationResultCode.SOS_MISMATCH) &&
      tags.includes(AccountHolderVerificationResultCode.SOS_MATCH)
    ) {
      return "Some states matched, while some did not.";
    }
    return "";
  })(tags);

  const activeString = ((
    tags: Maybe<AccountHolderVerificationResultCode>[],
  ): string => {
    if (
      tags.includes(AccountHolderVerificationResultCode.SOS_INACTIVE) &&
      !tags.includes(AccountHolderVerificationResultCode.SOS_ACTIVE)
    ) {
      return "All statuses were inactive.";
    }

    if (
      !tags.includes(AccountHolderVerificationResultCode.SOS_INACTIVE) &&
      tags.includes(AccountHolderVerificationResultCode.SOS_ACTIVE)
    ) {
      return "All statuses were active.";
    }

    if (
      tags.includes(AccountHolderVerificationResultCode.SOS_INACTIVE) &&
      tags.includes(AccountHolderVerificationResultCode.SOS_ACTIVE)
    ) {
      return "Statuses were both active and inactive.";
    }
    return "";
  })(tags);

  const unknownString = ((
    tags: Maybe<AccountHolderVerificationResultCode>[],
  ): string => {
    if (tags.includes(AccountHolderVerificationResultCode.SOS_UNKNOWN)) {
      return "At least one record was unknown.";
    }

    return "";
  })(tags);

  return {
    result: `${matchString} ${activeString} ${unknownString}`,
    icon:
      matchString === "All states matched." &&
      activeString === "All statuses were active." &&
      unknownString === ""
        ? "/img/approved-icon.svg"
        : "/img/decline-icon.svg",
  };
};

export const formatDocumentUploadSessionStatusCode = (
  status: Maybe<DocumentUploadSessionStatusCode>,
): string => (status ? title(status.replaceAll("_", " ")) : "");

export const formatAuthorizedUserApplicationStatusCode = (
  status?: AuthorizedUserApplicationStatusCode,
): string => {
  switch (status) {
    case AuthorizedUserApplicationStatusCode.APPROVED:
      return "Approved";
    case AuthorizedUserApplicationStatusCode.IN_REVIEW:
      return "In Review";
    case AuthorizedUserApplicationStatusCode.PENDING:
      return "Pending";
    case AuthorizedUserApplicationStatusCode.DENIED:
      return "Denied";
    case AuthorizedUserApplicationStatusCode.CLOSED:
      return "Closed";
    default:
      return "";
  }
};

export const formatVisaAmountSignage = (
  signage?: VisaAmountSignage,
): string => {
  switch (signage) {
    case VisaAmountSignage.CREDIT:
      return "Credit";
    case VisaAmountSignage.DEBIT:
      return "Debit";
    case VisaAmountSignage.NULL:
      return "";
    default:
      return "";
  }
};

export const formatVisaDiscountTreatment = (
  treatment: Maybe<VisaDiscountTreatment>,
): string => (treatment ? title(treatment.replaceAll("_", " ")) : "");

export const formatVisaTaxTreatment = (
  treatment?: VisaTaxTreatment,
): string => {
  switch (treatment) {
    case VisaTaxTreatment.NLL:
      return "Net prices with taxes calculated at the line item level";
    case VisaTaxTreatment.NIL:
      return "Net prices with taxes calculated at the invoice level";
    case VisaTaxTreatment.GLL:
      return "Gross prices with taxes calculated at the line item level";
    case VisaTaxTreatment.GIL:
      return "Gross prices with taxes calculated at the invoice level";
    case VisaTaxTreatment.NON:
      return "No tax applies on the invoice";
    default:
      return "";
  }
};

export const formatVisaItemCommodityCode = (
  commodityCode: Maybe<VisaItemCommodityCode>,
): string => (commodityCode ? title(commodityCode.replaceAll("_", " ")) : "");

export const formatVisaLineItemDetailIndicator = (
  detailIndicator: Maybe<VisaLineItemDetailIndicator>,
): string => {
  switch (detailIndicator) {
    case VisaLineItemDetailIndicator.CREDIT:
      return "Credit";
    case VisaLineItemDetailIndicator.PAYMENT:
      return "Payment";
    case VisaLineItemDetailIndicator.NORMAL:
      return "";
    default:
      return "";
  }
};

export const formatChargingTime = (time: string): string => {
  const HH = time.slice(0, 2);
  const MM = time.slice(2, 4);
  const SS = time.slice(4, 6);

  return `${HH}:${MM}:${SS} (HHMMSS)`;
};

export const formatValueWithMinorUnit = (
  value: number,
  minorUnit: Maybe<number>,
): number => {
  if (!minorUnit) {
    return value;
  }
  return value / Math.pow(10, minorUnit);
};

export const formatFeeScheduleStatus = (
  status: Maybe<FeeScheduleStatus>,
): string => (status ? title(status.replaceAll("_", " ")) : "");

export const formatFeeActivityType = (
  activityType:
    | FeeTransferEventFeeActivityTypeInput
    | FeeTransferEventFeeActivityType,
): string => title(activityType.replaceAll("_", " "), { special: ["ATM"] });

export const formatFeeChargeActivityTypename = (
  chargeActivityTypename: Maybe<string>,
): string => {
  switch (chargeActivityTypename) {
    case "PhysicalPaymentCardOrder":
      return "Physical Payment Card Order";
    case "Payment Card":
      return "Payment Card";
    case "FinancialAccount":
      return "Financial Account";
    case "SecureDepositACHTransfer":
      return "Secured Deposit (Originated ACH)";
    case "SecureCardBalanceRepaymentACHTransfer":
      return "Repayment (Originated ACH)";
    case "IntegratorInitiatedFundsWithdrawalACHTransfer":
      return "Withdrawal (Originated ACH)";
    case "IntegratorInitiatedFundsDepositACHTransfer":
      return "Deposit (Originated ACH)";
    case "AuthorizationAndClearEvent":
      return "Authorization And Clear";
    case "ClearingEvent":
      return "Clearing";
    case "BalanceInquiryEvent":
      return "Balance Inquiry";
    default:
      return "";
  }
};

export const formatNameMatch = (
  nameMatchString: Maybe<String>,
  revertDisplayString?: String,
): string => {
  if (revertDisplayString === "revertDisplayString") {
    switch (nameMatchString) {
      case "Match":
        return "EXACT_MATCH";
      case "Fuzzy Match":
        return "JARO_WINKLER_FUZZY_MATCH";
      case "Mismatch":
        return "MISMATCH";
      default:
        return "";
    }
  }
  switch (nameMatchString) {
    case "EXACT_MATCH":
      return "Match";
    case "JARO_WINKLER_FUZZY_MATCH":
      return "Fuzzy Match";
    case "MISMATCH":
      return "Mismatch";
    default:
      return "";
  }
};

export const formatAccountClosureBlocker = (
  blocker: Maybe<AccountClosureBlocker>,
): string => (blocker ? title(blocker.replaceAll("_", " ")) : "");

export const formatFinancialAccountClosureRequesterType = (
  requesterType: Maybe<FinancialAccountClosureRequesterType>,
): string => (requesterType ? title(requesterType.replaceAll("_", " ")) : "");

export const formatFinancialAccountCloseReason = (
  closeReason: Maybe<FinancialAccountCloseReason>,
): string => (closeReason ? title(closeReason.replaceAll("_", " ")) : "");
